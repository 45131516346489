import css from './sass/style.scss';

require('./js/anims');
require('./js/fonts');
require('./js/init');
require('./js/ismobile');
require('./js/lazy');
require('./js/nav');
require('./js/polyfills');
require('./js/text-roller');
require('./js/smoothscroll');
require('./js/validate');
